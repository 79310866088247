export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {
      padding: '0rem',
      position: ['static', 'static', 'static', 'static'],
      margin: '0rem auto 0rem 0rem',
      '.logo': {
        a: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        },
        img: {
          filter: 'unset',
          maxHeight: ['120px', '120px', '120px', '140px']
        }
      },
      '.logoScrolled': {
        img: {
          filter: 'unset',
          maxHeight: ['90px', '90px', '90px', '100px']
        }
      }
    },

    '.container': {
      padding: ['1rem 1rem', '1rem 2rem', '1rem 2rem', '1rem 3rem'],
      '.smallNavMenu': {
        '.navItem': {
          color: 'white',

          a: {
            fontSize: '1.3rem',
            textTransform: 'capitalize',
            ':hover': {
              textDecoration: 'underline'
            }
          }
        }
      }
    },
    '.containerScrolled': {
      padding: '0rem 1rem',
      '.smallNavMenu': {
        '.navItem': {
          color: 'dark',
          a: {
            textTransform: 'capitalize',
            fontSize: '1.3rem',
            ':hover': {
              textDecoration: 'underline'
            }
          }
        }
      }
    },

    '.hamburger': {
      borderColor: 'secondaryDark',
      borderRadius: '10px',
      display: ['', '', '', 'none'],
      '> div': {
        backgroundColor: 'secondaryDark'
      }
    },
    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      margin: '0rem 1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        backgroundColor: 'primary',
        padding: '0.5rem',
        color: 'light',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    },

    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      '.navMenuLogo': {
        maxHeight: '150px',
        marginBottom: '1.5rem',
        borderBottom: '1px solid',
        paddingBottom: '1.5rem',
        display: 'flex',
        img: {
          maxHeight: '150px',
          width: 'auto',
          objectFit: 'contain'
        },
        ':hover': {
          // transform: 'rotate(360deg)',
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },

      '.socialIconsContainer': {
        margin: '0rem 1rem',
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      }
    }
  },

  footer: {
    padding: ['2rem 1rem 6rem', '', '2rem 4rem'],
    '.logo': {
      // filter: 'brightness(0) invert(1)'
      '.image': {
        maxHeight: '300px'
      }
    },
    borderTop: 'solid 2px',
    borderColor: 'primary',
    color: 'white',
    '.multiButtonContainer': {
      a: {
        // variant: 'buttons.secondary',
      }
    },
    '.gonationLogo': {
      width: 'fit-content'
    },
    '.copyright': {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1rem'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3rem', '3rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'secondaryLight',
    order: '1'
  },
  subtitle: {
    color: 'secondaryDark',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.1rem', '1.25rem', '1.25rem'],
    marginBottom: '1.5rem',
    order: '2',
    // fontFamily: 'display',
    fontWeight: 'bold',
    fontFamily: 'body',
    border: 'none',
    borderBottom: 'dotted 2px #d7be82',
    borderTop: 'dotted 2px #d7be82',
    padding: '1rem 0rem'
  },
  text: {
    textAlign: 'left',
    lineHeight: '1.75',
    order: '3',
    p: {
      lineHeight: '1.75',
      textAlign: 'left',
      fontSize: 'inherit',
      fontWeight: 'bold'
    }
  },

  sideBySide1: {
    padding: ['1rem', '', '3rem', '4rem 4rem 4rem 1.5rem'],
    background: 'transparent',
    '.imageContainer': {
      margin: ['4rem 0rem', '', '0rem']
    },
    '.lazyload-wrapper': {
      position: 'relative',
      '::before': {
        content: "''",
        width: '96%',
        height: '100%',
        position: 'absolute',
        top: ['-3rem'],
        left: ['', '', '3rem'],
        backgroundColor: 'secondaryDark',
        zIndex: '-1',
        border: 'solid 10px',
        borderColor: 'secondaryLight'
      },
      maxHeight: '70vh',
      height: ['', '', '70vh'],
      img: {
        border: 'solid 7px',
        borderColor: 'primary'
      }
    },
    '.content': {
      // variant: 'customVariants.borderCorners',
      position: 'relative',
      alignItems: 'flex-start'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    padding: ['1rem', '', '3rem', '4rem 1.5rem 4rem 4rem'],

    '.content': {
      order: ['', '', '1'],
      alignItems: 'flex-start'
    },
    '.lazyload-wrapper': {
      position: 'relative',
      '::before': {
        content: "''",
        width: '96%',
        height: '100%',
        position: 'absolute',
        top: '-3rem',
        left: ['', '', '-3rem'],
        backgroundColor: 'secondaryDark',
        zIndex: '-1',
        border: 'solid 10px',
        borderColor: 'secondaryLight'
      },
      height: ['', '', '70vh'],
      maxHeight: '70vh',
      img: {
        border: 'solid 7px',
        borderColor: 'primary'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    marginTop: '1rem',
    '.section': {
      margin: 'auto auto',
      padding: '1rem',
      maxWidth: 'unset'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.75rem', '3.5rem', '5rem', '6.5rem', '8rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'white',
      textShadow: '1px 1px 20px black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      color: 'secondaryLight',
      textShadow: '1px 1px 20px black'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHeroShout: {},

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
    '.section': {
      width: ['100%', '', '50%'],
      alignItems: 'flex-start',
      textAlign: 'left',
      margin: ['auto', 'auto auto auto 10%'],
      padding: ['1rem', '2rem'],
      backgroundColor: '#382021c2',
      border: 'solid 10px',
      borderColor: 'secondaryLight'
    },

    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'left',
      p: {
        color: 'white',
        textAlign: 'left'
      }
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide2'
  },
  homepageCatering: {
    variant: 'customVariants.homepageMenu'
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },

  homepageContact: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
    '.text': {
      maxWidth: '600px',
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: '#8d1010',
      fontFamily: 'body'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['secondaryDark', '', '', 'secondaryDark'],
      color: '#e5d5c1'
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer'
    },

    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menuCtas: {
    variant: 'customVariants.sideBySide1',
    padding: ['1rem', '', '0rem'],

    width: '100%',
    mx: 'auto',
    backgroundColor: 'primary',
    '.content': {
      padding: '0rem 1.5rem',
      // width: ['100%', '', '50%', '50%'],
      '.title': {
        marginBottom: '0rem',
        color: 'white'
      }
    },
    '.ctaContainer': {
      // width: ['100%', '', '50%', '50%'],
      px: '2rem'
    },
    '.linksContainer': {
      width: '100%',
      margin: '0rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: ['center', '', 'flex-start'],
      alignItems: 'center',
      a: {
        width: ['fit-content', 'fit-content', 'fit-content', 'fit-content'],
        marginBottom: '0rem !important',
        my: '0.5rem !important'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      margin: '0rem',
      display: 'flex'
    },
    '.secondaryCtaButton': {
      variant: 'buttons.primary',
      margin: '0rem',

      display: 'flex'
    }
  },

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.albumName': {
      color: 'primary'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center',
      color: 'white'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Catering page  ===
  // ? ========================

  catering: {
    padding: '6rem 1rem',
    background: 'none',
    '.section': {
      '.ctaButton': {
        background: '#ff9933'
      }
    }
  },

  // ? ====================
  // ? ====  Blog page  ===
  // ? ====================

  blogPagehero: {
    '::before': {
      content: "''",
      position: 'absolute',
      position: 'absolute',
      top: '0%',
      left: '0%',
      backgroundColor: '#dcc7a9',
      width: '100%',
      height: ['151px', '', '', '171px']
    },
    height: 'auto',
    mb: '3rem',
    mt: ['151px', '', '', '171px'],
    height: '16vw',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    '.content': {
      display: 'none'
    }
  }
}
