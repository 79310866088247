export default {
  heading: 'Dancing Script, serif',
  body: 'Poiret One, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Fleur De Leah, cursive',
  googleFonts: [
    'Cinzel:100,200,300,400,500,600,700,800,900',
    'Hahmlet:100,200,300,400,500,600,700,800,900',
    'Koh Santepheap:100,300,400,700,900',
    'Cabin Sketch:400,700',
    'Fredericka the Great',
    'Dancing Script',
    'Fleur De Leah',
    'Playfair Display:400,500,600,700,800,900',
    'Poiret One'
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
