export default {
  text: '#515A47',
  primary: '#ff9933',
  primaryLight: '',
  primaryDark: '#7A4419',
  secondary: '#755C1B',
  secondaryLight: '#D7BE82',
  secondaryDark: '#501f07',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303'
}
